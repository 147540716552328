"use strict";

import "./js/inc/_jquery-global.js";
import "./scss/style.scss";

window.addEventListener("load", () => {

  // スマホメニュー表示・非表示
  const menuButton = document.querySelector(".js-navBtn");
  const headerNav = document.querySelector(".js-headerNav");
  const headerNavLinks = document.querySelectorAll(".js-headerNavLink");
  const headerBackground = document.querySelector(".js-headerBg");
  const headerLogo = document.querySelector(".js-headerLogo");
  const bodyElement = document.querySelector("body");
  const menuTL = gsap.timeline();

  const toggleNav = () => {
    menuButton.classList.toggle("is-open");
    headerNav.classList.toggle("is-open");
    headerBackground.classList.toggle("is-open");
    bodyElement.classList.toggle("is-open");
    headerLogo.classList.toggle("is-opacity");
  };
  const removeNav = () => {
    menuButton.classList.remove("is-open");
    headerNav.classList.remove("is-open");
    headerBackground.classList.remove("is-open");
    bodyElement.classList.remove("is-open");
    headerLogo.classList.remove("is-opacity");
  };

  menuButton.addEventListener("click", () => {
    if(menuButton.classList.contains('is-open')) {
      removeNav();
      menuTL
      .fromTo(headerNav, {autoAlpha:1}, {autoAlpha:0});
    } else {
      toggleNav();
      menuTL
      .fromTo(headerNav, {autoAlpha:0}, {autoAlpha:1})
    }
  });
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (windowWidth <= 992) {
    headerNavLinks.forEach((navLink) => {
      navLink.addEventListener("click", () => {
        removeNav();
        menuTL
        .fromTo(headerNav, {autoAlpha:1}, {autoAlpha:0});
      });
    });
  }
  headerBackground.addEventListener("click", removeNav);

  // header スクロール変化
  const headerHeight = $('.js-header').outerHeight(true);

  function headerChange() {
    const scroll = $(window).scrollTop();
    if (scroll >= headerHeight) {
      $('.js-header').addClass('is-active');
    } else {
      $('.js-header').removeClass('is-active');
    }
  }

  $(window).on('scroll', function () {
    headerChange();
  });

  // スライダー
  const photoSlider = document.querySelector('.js-photoSlider');
  if(photoSlider) {
    const mySwiper = new Swiper('.js-photoSlider', {
      loop: true,
      speed: 4000,
      spaceBetween: 20,
      SimulateTouch: false,
      autoplay: {
        delay: 0,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
      }
    });
  }

  // FAQ
  const faq = document.querySelector('.js-faq');
  if(faq) {
    $(".js-faqCheckups .js-faqBox:first-of-type .js-faqAnswer").css("display", "block");
    $(".js-faqCheckups .js-faqBox:first-of-type .js-faqAnswer").addClass("is-active");
    $(".js-faqCheckups .js-faqBox:first-of-type .js-faqQuestion").addClass("is-active");
  
    $(".js-faqQuestion").on("click", function () {
      $(".js-faqQuestion").not(this).next().slideUp(400);
      $(".js-faqQuestion").not(this).next().removeClass("is-active");
      $(".js-faqQuestion").not(this).removeClass("is-active");
      $(this).next().slideToggle(400);
      $(this).next().toggleClass("is-active");
      $(this).toggleClass("is-active");
    });
  }

  // TOPに戻る・アンカーリンク位置調整
  $(function(){
    $('a[href^="#"]').click(function() {
      let speed = 500;
      let type = 'swing';
      let href= $(this).attr("href");
      let target = $(href == "#" ? 'html' : href);
      let position = target.offset().top - 100;
      $('body,html').animate({scrollTop:position}, speed, type);
      return false;
    });
  });
});

window.addEventListener('scroll', () => {
  // 追従ボタン footer直前で止まる
  const elem = document.querySelector('.js-pageTop');
  const docHeight = document.body.clientHeight;
  const scrollCount = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollPos = document.documentElement.clientHeight + scrollCount;
  const footerHeight = document.querySelector('.js-footer').clientHeight;

  const currentY = window.pageYOffset;

  if(currentY >= 200) {
    setTimeout(() => {
      elem.style.opacity = 1;
      elem.style.visibility = 'visible';
    }, 1);
  } else {
    setTimeout(() => {
      elem.style.opacity = 0;
      elem.style.visibility = 'hidden';
    }, 1);
  }

  if (docHeight - scrollPos <= footerHeight) {
    elem.style.position = 'absolute';
    elem.style.bottom = footerHeight - 30 + 'px';      
  } else {
    elem.style.position = 'fixed';
    elem.style.bottom = 20 + 'px';
  }
});
